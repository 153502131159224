import { shouldLog, log } from './jdl-helpers';

const components = [
	{
		name: 'form',
		selectors: ['form'],
	},
	{
		name: 'popdown',
		selectors: ['#popdown-outer-stage'],
	},
];

components.forEach((component) => {
	const name = component.name;
	const selector = component.selectors.join(', ');

	if (document.querySelectorAll(selector).length > 0) {
		import(`./${name}.jdl.js`).then((module) => {
			if (typeof module.handleEvents === 'function') {
				module.handleEvents();
				shouldLog && log(`Loaded ${name} handler.`);
			}
		});
	}
});
