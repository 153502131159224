export const eventPrefix = 'jdl_';
export const theme = window.theme;

// Check for debug query param
export const shouldLog =
	window.location.search.includes('jdl-debug') ||
	window.location.hostname === '127.0.0.1';

export function log(message, heading = null) {
	const styles = 'background:#555;color:#fff;padding:4px 8px;';
	const headingString = heading ? ` | ${heading}` : '';

	console.log(`%cJonah${headingString}`, styles, message);
}

// Push data to the global dataLayer
export function push(data, log = false) {
	if (!Array.isArray(window.dataLayer)) {
		window.dataLayer = [];
	}

	window.dataLayer.push(data);

	if (log) {
		const styles = 'background:#555;color:#fff;padding:4px 8px;';
		console.log(`%cJonah ${data.event}`, styles, data);
	}
}

// Calculate the number of days between two dates
export function daysBetween(date1, date2) {
	const millisInDay = 1000 * 60 * 60 * 24;
	const diffInDays = Math.floor((date1 - date2) / millisInDay) + 1;
	return diffInDays;
}

// Format a Date object as MM/DD/YYYY
export function getFormattedDate(dateToFormat) {
	const month = dateToFormat.getMonth() + 1;
	const day = dateToFormat.getDate();
	const year = dateToFormat.getFullYear();
	return `${month}/${day}/${year}`;
}

// Wait for a global variable to exist
export function waitForVariable(
	variableName,
	callback,
	timeout = 5000,
	interval = 100,
) {
	const startTime = Date.now();
	const checkInterval = setInterval(() => {
		if (window[variableName]) {
			clearInterval(checkInterval);
			callback(window[variableName]);
		}

		if (Date.now() - startTime > timeout) {
			clearInterval(checkInterval);
		}
	}, interval);
}

// Wait for elements matching a selector to be present in the DOM
export function waitForElements(
	selector,
	callback,
	ancestor = document,
	timeout = 5000,
	interval = 100,
) {
	const startTime = Date.now();
	const checkInterval = setInterval(() => {
		const elements = ancestor.querySelectorAll(`:scope ${selector}`);

		if (elements.length > 0) {
			clearInterval(checkInterval);
			callback(elements);
		}

		if (Date.now() - startTime > timeout) {
			clearInterval(checkInterval);
		}
	}, interval);
}
